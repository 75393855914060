<template>
    <div class="tools-container">
        <div v-if="rolePermission.access_permission.length">
            <div class="home-tools">
                <div class="tools-title">运营工具</div>
                <div class="tools-list">
                    <div
                        class="list-item"
                        v-for="appItem in availableAppList"
                        v-show="showApp(appItem)"
                        :key="appItem.name"
                        @click="gotoApp(appItem)"
                    >
                        <img class="logo" :src="appItem.icon" />
                        <span>{{ appItem.name }}</span>
                    </div>
                </div>
            </div>
            <div class="account-operate" v-if="isInMiniprogram">
                <div
                    class="account-operate__cell"
                    v-for="item in operateList"
                    @click="handleOperateClick(item.eventType)"
                    :key="item.id"
                >
                    {{ item.text }}
                </div>
            </div>
            <div class="footer-contact un_select">
                <div>
                    使用有疑问？请
                    <a @click="houseKeeperShow = true" href="javascript:void(0)">咨询客服</a>
                </div>
            </div>
        </div>

        <!-- 默认不展示无权限页面 -->
        <no-permission v-else-if="isLoadedPermission" />

        <service-dialog
            v-show="houseKeeperShow"
            :houseKeeperStatus="houseKeeperStatus"
            :keeperInfo="houseKeeperInfo"
            @close="houseKeeperShow = false"
        ></service-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import serviceDialog from '@/components/serviceDialog.vue';
import noPermission from '@/components/no-permission';
import { loginOut, getBatchAddCustomerLink } from '@/api';
import { openExpireTimeDialog } from '@/utils/tools';
export default {
    components: {
        serviceDialog,
        noPermission
    },
    props: {
        isPromoter: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            appList: [
                {
                    name: '渠道活码',
                    path: '/channel_liveCode?type=channel',
                    icon: require('@/assets/images/code.png'),
                    roles: 101
                },
                {
                    name: '群组活码',
                    path: '/group_liveCode?type=group',
                    icon: require('@/assets/images/code_group.png'),
                    roles: 111
                },
                {
                    name: '客户群发',
                    path: '/customer_contact',
                    icon: require('@/assets/images/customer_send.png'),
                    roles: 31
                },
                {
                    name: '客户列表',
                    path: '/userlist',
                    icon: require('@/assets/images/customer_list.png'),
                    roles: 301
                },
                {
                    name: '客户统计',
                    path: '/customer_statistics',
                    icon: require('@/assets/images/customer_number.png'),
                    roles: 33
                },
                {
                    name: '海报素材',
                    path: '/poster_list',
                    icon: require('@/assets/images/app_poster.png'),
                    roles: 0,
                    needExternalCustomerSecret: 1
                },
                {
                    name: '企业名片',
                    path: '/my_card',
                    icon: require('@/assets/images/app_e_card.png'),
                    roles: 0,
                    needExternalCustomerSecret: 1
                },
                {
                    name: '锁客',
                    path: '/staff_promotion',
                    icon: require('@/assets/images/staff_promotion_icon.png'),
                    isPromoter: 1
                },
                {
                    name: '批量加客户',
                    isLink: true,
                    icon: require('@/assets/images/batch_add_customer.png'),
                    roles: 0
                }
            ],
            operateList: [
                {
                    id: 1,
                    text: '切换企业',
                    eventType: 'toggleEnterprise'
                },
                {
                    id: 2,
                    text: '退出登录',
                    eventType: 'loginOut'
                }
            ],
            houseKeeperInfo: {
                houseKeeperName: '',
                houseKeeperSex: 1, // 1男 2女用不同头像
                houseKeeperQrcode: ''
            },
            houseKeeperShow: false,
            houseKeeperStatus: 0, //0售前 1服务 2下班
            batchAddCustomerLink: ''
        };
    },
    computed: {
        ...mapState(['rolePermission', 'isLoadedPermission', 'isInMiniprogram', 'remainingSeconds']),
        availableAppList() {
            let appList = [];
            appList = this.appList.filter((item) => {
                // 如果是推广员、展示推广员入口
                if (item.isPromoter) {
                    return this.isPromoter;
                }
                // 海报素材、企业名片不做权限校验
                if (item.roles === 0) {
                    return true;
                }
                return this.rolePermission.access_permission.includes(item.roles);
            });
            return appList;
        }
    },
    created() {
        this.getHouseKeeperStatus();
        this.BatchAddCustomerLink();
    },
    methods: {
        BatchAddCustomerLink() {
            getBatchAddCustomerLink({})
                .then((res) => {
                    console.log('res', res);
                    this.batchAddCustomerLink = res.data;
                })
                .catch((error) => {
                    this.$toast.fail(error.msg);
                });
        },
        handleOperateClick(eventType) {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: '加载中...'
            });
            let redirectUrl, routeApi;
            switch (eventType) {
                case 'toggleEnterprise':
                    redirectUrl = './index';
                    routeApi = 'redirectTo';
                    break;
                case 'loginOut':
                    redirectUrl = '../home/index';
                    routeApi = 'switchTab';
                    break;
            }

            loginOut()
                .then(({ code }) => {
                    if (code === 0) {
                        wx.miniProgram[routeApi]({
                            url: redirectUrl
                        });
                    }
                })
                .catch((err) => {
                    this.$toast(err);
                });
        },

        /**
         * 是否展示app入口
         */
        showApp(appItem) {
            if (!('needExternalCustomerSecret' in appItem) || !appItem.needExternalCustomerSecret) {
                return true;
            }
            if (appItem.needExternalCustomerSecret && this.$store.state.isConfigedSecret) {
                return true;
            }
            return false;
        },
        gotoApp(appItem) {
            if (appItem.isLink && this.batchAddCustomerLink) {
                window.location.href = this.batchAddCustomerLink;
                return false;
            }
            // 拦截群发编辑
            if (appItem.path === '/customer_contact' && this.remainingSeconds <= 0) {
                openExpireTimeDialog();
                return false;
            }
            this.$router.push({
                path: appItem.path
            });
            this.sensors(appItem.name);
        },
        sensors(name) {
            try {
                window.sensors.track('$click_butt', {
                    app_id: this.app_id,
                    wework_user_id: this.wework_user_id,
                    page_name: '首页',
                    page_button: name,
                    page_id: 'B#qiwei_mobile_admin#index',
                    l_program: '企微移动端管理台',
                    page_path: 'B端#企微移动端管理台#首页'
                });
            } catch (err) {
                console.log(err);
            }
        },
        //获取客服码
        async getHouseKeeperStatus() {
            let is_qw_try = await this.is_qw_try_version();
            //试用版显示售前码
            if (is_qw_try.flag) {
                this.houseKeeperStatus = 0;
            } else {
                this.$ajax('get_pop_window_info').then((res) => {
                    if (res.data && res.data.code === 0 && res.data.data.qr_code) {
                        let data = res.data.data;
                        // 二维码 + 服务管家信息
                        this.houseKeeperInfo.houseKeeperQrcode = data.qr_code;
                        this.houseKeeperInfo.houseKeeperSex = data.gender;
                        this.houseKeeperInfo.houseKeeperName = data.deliver_staff_name;
                        this.houseKeeperStatus = 1;
                    } else {
                        this.houseKeeperStatus = 2;
                    }
                });
            }
        },
        //判断试用版店铺
        is_qw_try_version() {
            return new Promise((resolve, reject) => {
                this.$ajax('is_qw_try_version').then((res) => {
                    if (res.data && res.data.code === 0) {
                        resolve(res.data.data);
                    } else {
                        reject(res.data.msg);
                    }
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.account-operate {
    margin-top: 0.32rem;
    color: #333333;
    overflow: hidden;

    &__cell {
        padding: 0.24rem;
        background-color: #fff;
        overflow: hidden;
        width: 100%;
        box-sizing: border-box;
        text-align: center;

        &:last-child {
            margin-top: 0.06rem;
        }
    }
}

.home-tools {
    margin-top: 0.32rem;
    background-color: #ffffff;
    padding: 0.32rem;
    color: #333333;
    border-radius: 0.16rem;
    .tools-title {
        font-size: 0.32rem;
        font-weight: 500;
        line-height: 0.48rem;
    }
    .tools-list {
        margin-top: 0.24rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -0.32rem;
    }
    .list-item {
        // width: 1.44rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.24rem;
        line-height: 0.36rem;
        cursor: pointer;
        width: 25%;
        margin-bottom: 0.32rem;
        img {
            width: 0.96rem;
            height: 0.96rem;
            margin-bottom: 0.16rem;
        }
    }
    .logo {
        width: 0.96rem;
        height: 0.96rem;
        border-radius: 0.24rem;
    }
}
.footer-contact {
    padding: 0.96rem 0 0.64rem;
    font-size: 0.24rem;
    font-weight: 400;
    color: #333333;
    line-height: 0.34rem;
    text-align: center;
    width: 100%;
    span {
        color: #105cfb;
        cursor: pointer;
    }
}
</style>
