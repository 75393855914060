<template>
    <div class="user-info">
        <div class="user-info__avatar">
            <img class="un_select" v-default-avatar :src="staffInfo.avatar" alt="头像" />
        </div>
        <div class="user-info__identity">
            <div class="user-info__name">
                <open-data :id="staffInfo.id" :name="staffInfo.name" />
            </div>
            <router-link v-if="staffInfo.phone" to="/edit_info" class="user-info__phone" tag="div">
                <van-icon name="phone-o" />
                <span>{{ staffInfo.phone }}</span>
                <van-icon name="arrow" />
            </router-link>
            <router-link v-else-if="!loading" to="/edit_info" class="user-info__phone" tag="div">
                <van-icon name="edit" />
                <span>添加我的手机号</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import openData from '@/components/openData';

export default {
    components: {
        openData
    },
    data() {
        return {
            staffInfo: {
                avatar: '',
                phone: '',
                user_name: ''
            },
            loading: false
        };
    },
    activated() {
        this.getCardDetail();
    },
    computed: {
        hasEPromoter() {
            return Boolean(this.$store.state.sales_crm_user?.is_show_goose_button);
        }
    },
    methods: {
        getCardDetail() {
            this.loading = true;
            this.$ajax('get_card_detail')
                .then(({ data }) => {
                    if (data && data?.code == 0 && data?.data) {
                        const { pic_url, user_name, phone, user_id, sales_crm_user } = data.data;
                        this.staffInfo.avatar = pic_url;
                        this.staffInfo.name = user_name;
                        this.staffInfo.phone = phone;
                        this.staffInfo.id = user_id;
                        this.setSalesCrmUser(sales_crm_user);
                    } else {
                        return Promise.reject(data);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(err.msg || '获取员工信息失败');
                    console.error(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setSalesCrmUser(data) {
            this.$store.commit('setSalesCrmUser', data);
        }
    }
}
</script>

<style lang="scss" scoped>
.user-info {
    display: flex;
    align-items: center;
    padding: 0.24rem 0.32rem;
    margin-bottom: 0.32rem;
    background: #fff;
    border-radius: 0.16rem;
    &__avatar {
        width: 1rem;
        height: 1rem;
        margin-right: 0.32rem;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__identity {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        overflow: hidden;
    }
    &__name {
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__phone {
        cursor: pointer;
        .van-icon {
            margin-right: 0.16rem;
            &-arrow {
                margin-left: 0.12rem;
            }
        }
    }
}
</style>
