<template>
    <div class="tabbar-list-container tabbar--fixed">
        <div
            class="tabbar-item"
            :class="{ 'active-item': activeTab === item.id }"
            v-for="(item, key) in tabs"
            :key="key"
            @click="selectTab(item)"
        >
            <div class="icon-wrapper">
                <img :src="activeTab === item.id ? item.activeIcon : item.icon" />
            </div>
            <div class="text-wrapper">
                <span>{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Array,
            default: () => []
        },
        defaultActive: {
            type: [String, Number],
            default: 1
        }
    },
    data() {
        return {
            activeTab: 3
        };
    },
    mounted() {},
    methods: {
        selectTab(item) {
            this.activeTab = item.id;
            window.location.href = item.url;
        }
    }
};
</script>

<style scoped lang="scss">
.tabbar-list-container {
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: 1.5rem;
    background-color: #ffffff;
    max-width: 480px;
    .tabbar-item {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 1;
    }
    .icon-wrapper {
        img {
            height: 0.56rem;
            width: 0.56rem;
        }
    }
    .text-wrapper {
        span {
            font-size: 0.2rem;
            color: #999999;
        }
    }
    .active-item {
        span {
            color: #1472ff;
        }
    }
}
.tabbar--fixed {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
</style>
