<template>
  <div class="keeper-popup fixed" @click="closeKeeper">
    <div
      class="popup-container un_select"
      :class="houseKeeperStatus == 2 ? 'bg-white' : ''"
    >
      <div class="popup-top">
        <img
          v-if="houseKeeperStatus == 2"
          class="popup-close"
          src="@/assets/images/close_black.png"
          @click="closeKeeper"
        />
        <img
          v-else
          class="popup-close"
          src="@/assets/images/close.png"
          @click="closeKeeper"
        />
      </div>
      <div class="popup-center" v-if="houseKeeperStatus == 0">
        <img
          class="qrcode-image"
          src="https://wechatapppro-1252524126.file.myqcloud.com/apppcHqlTPT3482/image/b_u_5b73e35149a67_ypMtbWtO/kluah5gg0aa4.png"
        />
        <div class="center-title">老师您好，我是您的专属顾问</div>
        <div class="center-tips">
          咨询答疑 · 案例推荐 · 订购政策 · 服务支持
        </div>
        <img
          class="center-code"
          src="@/assets/images/sale_code.png"
        />
        <div class="center-tips">长按识别二维码，或保存图片</div>
        <a class="popup-button button-tel" href="tel:400-102-6665"
          >400-102-6665</a
        >
      </div>
      <div class="popup-center" v-if="houseKeeperStatus == 1">
        <img
          class="qrcode-image"
          :src="keeperInfo.houseKeeperSex==1?'https://wechatapppro-1252524126.file.myqcloud.com/apppcHqlTPT3482/image/b_u_5b73e35149a67_ypMtbWtO/kluah5gj0w0w.png':'https://wechatapppro-1252524126.file.myqcloud.com/apppcHqlTPT3482/image/b_u_5b73e35149a67_ypMtbWtO/kluah5gg0aa4.png'"
        />
        <div class="center-title">您好，我是您的专属服务管家</div>
        <div class="center-tips">
          咨询答疑 · 案例推荐 · 订购政策 · 服务支持
        </div>
        <img
          class="center-code"
          :src="keeperInfo.houseKeeperQrcode"
        />
        <div class="center-tips">长按识别二维码，或保存图片</div>
        <a class="popup-button button-tel" href="tel:400-102-6665"
          >400-102-6665</a
        >
      </div>
      <div class="popup-center" v-if="houseKeeperStatus == 2">
        <div class="center-tel">400-102-6665</div>
        <div class="popup-buttons">
          <button class="buttons button-cancel" @click="closeKeeper">
            取消
          </button>
          <a class="buttons button-confirm" href="tel:400-102-6665"
            >拨打</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "serviceDialog",
  props: {
    keeperInfo: {
      default: () => {},
    },
    houseKeeperStatus: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeKeeper() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.keeper-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  .popup-container {
    width: 80%;
    padding-bottom: 0.48rem;
    background: linear-gradient(180deg, #1491ff 0%, #1472ff 100%);
    border-radius: 0.16rem;
    &.bg-white {
      background: #ffffff;
    }
    .popup-top {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 0.24rem;
      padding-right: 0.24rem;
    }
    .popup-close {
      width: 0.32rem;
      height: 0.32rem;
    }
  }
  .popup-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode-image {
      width: 1.36rem;
      height: 1.36rem;
    }
    .center-title {
      width: 85%;
      text-align: center;
      margin-top: 0.24rem;
      font-size: 0.32rem;
      font-weight: 500;
      line-height: 0.48rem;
      height: 0.48rem;
      color: rgba(255, 255, 255, 1);
    }
    .center-code {
      margin-top: 0.3rem;
      width: 3rem;
      height: 3rem;
    }
    .center-tips {
      width: 90%;
      text-align: center;
      margin-top: 0.16rem;
      font-size: 0.24rem;
      color: rgba(255, 255, 255, 0.8);
      line-height: 0.36rem;
      height: 0.36rem;
    }
    .center-tel {
      width: 60%;
      text-align: center;
      margin-top: 0.4rem;
      margin-bottom: 0.48rem;
      font-size: 0.32rem;
      font-weight: 500;
      color: #333333;
    }
    .popup-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .buttons {
      width: 2.4rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      color: #333333;
      font-size: 0.32rem;
      outline: none;
      border: none;
      text-align: center;
      line-height: 0.8rem;
    }
    .button-cancel {
      border: 1px solid #cccccc;
      background-color: #ffffff;
    }
    .button-confirm {
      margin-left: 0.32rem;
      background: #1472ff;
      color: #ffffff;
    }
    .popup-button {
      margin-top: 0.3rem;
      height: 0.7rem;
      width: 60%;
      border-radius: 0.3rem;
      text-align: center;
      line-height: 0.7rem;
      font-size: 0.3rem;
      outline: none;
      border: none;
      color: #1472ff;
      background-color: #fff;
    }
  }
}
</style>
