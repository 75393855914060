<template>
    <div class="crm-tool-container">
        <div class="tools-title">CRM工具</div>

        <div class="tools-list" :class="{ 'tools-list_flex-start': appList.length < 3 }">
            <div
                class="list-item"
                v-for="appItem in appList"
                v-show="showApp(appItem)"
                :key="appItem.name"
                @click="gotoApp(appItem)"
            >
                <img class="logo" :src="appItem.icon" />

                <span>{{ appItem.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    data() {
        return {};
    },
    created() {},
    computed: {
        isDev() {
            const href = location.href;
            return href.includes('inside') || href.includes('localhost') || href.includes('127.0.0');
        },
        appList() {
            return this.hasEPromoter
                ? [
                      {
                          name: '获客雷达',
                          path: 'https://admin.h5.sales-crm.xiaoe-tech.com/#/customer_get',
                          devPath: 'https://h5.sales-crm.inside.xiaoe-tech.com/#/customer_get',
                          icon: require('@/assets/images/rada.png'),
                          roles: 101
                      },
                      {
                          name: '我的线索',
                          path: 'https://admin.h5.sales-crm.xiaoe-tech.com/#/index/clue_my',
                          devPath: 'https://h5.sales-crm.inside.xiaoe-tech.com/#/index/clue_my',
                          icon: require('@/assets/images/clue.png'),
                          roles: 101
                      },
                      {
                          name: '鹅推广',
                          path: 'https://admin.h5.sales-crm.xiaoe-tech.com/#/applet_boot_page',
                          devPath: 'https://h5.sales-crm.inside.xiaoe-tech.com/#/applet_boot_page',
                          icon: require('@/assets/images/promote.png'),
                          roles: 101
                      }
                  ]
                : [
                      {
                          name: '获客雷达',
                          path: 'https://admin.h5.sales-crm.xiaoe-tech.com/#/customer_get',
                          devPath: 'https://h5.sales-crm.inside.xiaoe-tech.com/#/customer_get',
                          icon: require('@/assets/images/rada.png'),
                          roles: 101
                      },
                      {
                          name: '我的线索',
                          path: 'https://admin.h5.sales-crm.xiaoe-tech.com/#/index/clue_my',
                          devPath: 'https://h5.sales-crm.inside.xiaoe-tech.com/#/index/clue_my',
                          icon: require('@/assets/images/clue.png'),
                          roles: 101
                      }
                  ];
        },
        hasEPromoter() {
            return Boolean(this.$store.state.sales_crm_user?.is_show_goose_button);
        }
    },
    methods: {
        /**
         * 是否展示app入口
         */
        showApp(appItem) {
            console.log(appItem, 'appItem');
            return true;
        },
        gotoApp(appItem) {
            const path = this.isDev ? appItem.devPath : appItem.path;
            window.location.href = path;
        }
    }
};
</script>

<style scoped lang="scss">
.crm-tool-container {
    margin-top: 0.32rem;
    background-color: #ffffff;
    padding: 0.32rem;
    color: #333333;
    border-radius: 0.16rem;
    .tools-title {
        font-size: 0.32rem;
        font-weight: 500;
        line-height: 0.48rem;
    }
    .tools-list {
        margin-top: 0.24rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: -0.32rem;
    }
    .tools-list_flex-start {
        justify-content: flex-start;
    }
    .list-item {
        // width: 1.44rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.24rem;
        line-height: 0.36rem;
        cursor: pointer;
        width: 25%;
        margin-bottom: 0.32rem;
        img {
            width: 0.56rem;
            height: 0.56rem;
            margin-bottom: 0.16rem;
        }
    }
    .logo {
        width: 0.96rem;
        height: 0.96rem;
        border-radius: 0.24rem;
    }
}
</style>
