<template>
    <div class="home un_select">
        <!-- 用户信息 -->
        <user-info />
        <!-- 客户数据统计 -->
        <div v-if="rolePermission.access_permission.includes(33)" class="home-data" @click="jumpCustomerStatistics">
            <div class="data-item">
                <span class="data-name">企微好友总数</span>
                <span class="data-number">{{ customer_count }}</span>
            </div>
            <div class="data-item">
                <span class="data-name">今日新增</span>
                <span class="data-number">{{ new_contact_cnt }}</span>
            </div>
            <div class="data-item">
                <span class="data-name">今日流失</span>
                <span class="data-number">{{ negative_feedback_cnt }}</span>
            </div>
        </div>
        <!-- CRM工具 -->
        <CrmTool v-if="hasCRM" />
        <!-- 常用工具九宫格菜单 -->
        <app-entrance :isPromoter="isPromoter"></app-entrance>
        <Tabbar :tabs="tabs" />
    </div>
</template>

<script>
import { toThousands } from '@/globalWidget/utils/formatData.js';
import { getCookie } from '@/utils/cookies.js';
import AppEntrance from '@/components/appEntrance';
import UserInfo from '@/components/user-info';
import CrmTool from '@/components/crm-tool';
import { mapState } from 'vuex';
import Tabbar from '@/globalWidget/modules/tabbar/index.vue';

export default {
    name: 'Home',
    components: {
        AppEntrance,
        UserInfo,
        CrmTool,
        Tabbar
    },
    data() {
        return {
            customer_count: 0,
            negative_feedback_cnt: 0,
            new_contact_cnt: 0,
            wework_user_id: '',
            isPromoter: 0
        };
    },
    computed: {
        ...mapState(['rolePermission', 'isConfigedSecret']),
        tabs() {
            return [
                {
                    name: '工作台',
                    id: 3,
                    activeIcon: require('@/assets/images/workSpace-active.svg'),
                    icon: require('@/assets/images/workSpace-default.svg'),
                    url: this.isDev
                        ? 'https://h5.qiweiadmin.inside.xiaoe-tech.com/'
                        : 'https://h5.qiweiadmin.xiaoe-tech.com'
                },
                {
                    name: 'CRM',
                    id: 2,
                    activeIcon: require('@/assets/images/crm-active.svg'),
                    icon: require('@/assets/images/crm.svg'),
                    url: this.isDev
                        ? 'https://h5.sales-crm.inside.xiaoe-tech.com/#/index/clue_pool'
                        : 'https://admin.h5.sales-crm.xiaoe-tech.com/#/index/clue_pool'
                },
                {
                    name: '待办事项',
                    id: 1,
                    activeIcon: require('@/assets/images/task-active.svg'),
                    icon: require('@/assets/images/task.svg'),
                    url: this.isDev
                        ? 'https://h5.sales-crm.inside.xiaoe-tech.com/#/todo_task_list'
                        : 'https://admin.h5.sales-crm.xiaoe-tech.com/#/todo_task_list'
                }
            ];
        },
        isDev() {
            const href = location.href;
            return href.includes('inside') || href.includes('localhost') || href.includes('127.0.0');
        },
        hasCRM() {
            return Boolean(this.$store.state.sales_crm_user?.user_status);
        }
    },
    async created() {
        //获取首页数据
        this.get_user_behavior_data();
        this.clearVuexValue();
        this.get_user_role();
    },
    activated() {
        window.addEventListener('popstate', this.closeWindow, true);
    },

    beforeRouteLeave(to, from, next) {
        window.removeEventListener('popstate', this.closeWindow, true);
        next();
    },

    methods: {
        //获取客户数据
        get_user_behavior_data() {
            this.$ajax('get_user_behavior_data').then((res) => {
                if (res.data && res.data.code === 0) {
                    const data = res.data.data;
                    this.customer_count = toThousands(data.customer_count);
                    this.negative_feedback_cnt = toThousands(data.negative_feedback_cnt);
                    this.new_contact_cnt = toThousands(data.new_contact_cnt);
                }
            });
        },
        //获取角色
        get_user_role() {
            this.$ajax('get_user_role').then((res) => {
                const { code, data } = res.data;
                if (code === 0) {
                    this.isPromoter = data.is_distributor;
                    if (data.wework_user_id) {
                        this.wework_user_id = data.wework_user_id;
                        localStorage.setItem('userId', this.wework_user_id);
                    }
                }
                localStorage.setItem('b_user_id', res.data.b_user_id);
            });
        },
        jumpCustomerStatistics() {
            this.sensors('实时数据 - 任意数字');
            this.$router.push({ name: 'customerStatistics' });
        },
        clearVuexValue() {
            this.$store.dispatch('resetData');
        },
        sensors(name) {
            try {
                window.sensors.track('$click_butt', {
                    app_id: getCookie('with_app_id'),
                    wework_user_id: this.wework_user_id,
                    page_name: '首页',
                    page_button: name,
                    page_id: 'B#qiwei_mobile_admin#index',
                    l_program: '企微移动端管理台',
                    page_path: 'B端#企微移动端管理台#首页'
                });
            } catch (err) {
                console.log(err);
            }
        },
        closeWindow() {
            wx.closeWindow();
        }
    }
};
</script>
<style lang="scss" scoped>
.home {
    padding: 0.32rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    background: #f5f6f9;
    box-sizing: border-box;
}
.home-data {
    position: relative;
    background-color: #ffffff;
    border-radius: 0.16rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.48rem 0.32rem;
    cursor: pointer;
    .data-item {
        display: flex;
        flex-direction: column;
        color: #333333;
    }
    .data-name {
        font-size: 0.28rem;
        font-weight: 400;
        line-height: 0.4rem;
        color: #333333;
    }
    .data-number {
        font-size: 0.4rem;
        font-family: D-DIN-Bold, D-DIN;
        font-weight: bold;
        line-height: 0.56rem;
        margin-top: 0.16rem;
    }
}
</style>
